import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql, Link, navigate } from "gatsby";

import NavLink from "../components/NavLink";

import "./layout.css";

const mainNav = ["/", "/news", "/sales", "/maintenance", "/eng", "/contact"];

const Layout = props => {
  const year = new Date().getFullYear();
  const { children, location } = props;

  const getLink = (data, path) => {
    path = path || location.pathname;
    let result = data.allLinksJson.edges.find(edge =>
      edge.node.path === "/" ? path === "/" : path.startsWith(edge.node.path)
    );
    return result
      ? result.node
      : data.allLinksJson.edges.find(edge => edge.node.path === "/").node;
  };

  return (
    <StaticQuery
      query={query}
      render={data => (
        <div id="mainslot">
          <Helmet
            title={`${getLink(data).title} | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: "description",
                content: getLink(data).subheadText
              },
              { name: "keywords", content: "sample, something" }
            ]}
          />
          <h1 id="logo" className="handy">
            <Link to="/">Bijan Air, Inc.</Link>
          </h1>
          <h2 id="sub_title">{getLink(data).title}</h2>
          <h3 id="tagline" className="handy" onClick={() => navigate("/")}>
            <span id="tagleft">Providing valuable aviation services</span>
            <span id="tagright">to the helicopter community since 1988</span>
          </h3>
          <ol id="nav">
            {mainNav.map(link => (
              <NavLink key={link} current={location.pathname} path={link}>
                {getLink(data, link).name}
              </NavLink>
            ))}
          </ol>
          <div>{children}</div>
          <div id="footer">
            <ul>
              {mainNav.map(link => (
                <NavLink
                  key={link + "Footer"}
                  current={location.pathname}
                  path={link}
                  suffix="footer"
                >
                  {getLink(data, link).name}
                </NavLink>
              ))}
            </ul>
            <div id="copyright">&copy;{year} Bijan Air, Inc.</div>
          </div>
        </div>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};

export default Layout;

const query = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
      }
    }

    allLinksJson {
      edges {
        node {
          title
          path
          name
          msg
          img1
          img2
          heading
          subhead
          subheadIndex
          subheadText
          sideImage
        }
      }
    }
  }

  fragment helicopterImageFields on File {
    childImageSharp {
      fluid(maxWidth: 128) {
        src
        originalImg
      }
    }
  }

  fragment helicopterFrontmatter on MarkdownRemark {
    id
    frontmatter {
      year
      make
      model
      tail
      price
      airframe
      engine
      engine2
      serialNumber
      summary
      reduced
      sold
      new
      exteriorImage {
        ...helicopterImageFields
      }
      interiorImage {
        ...helicopterImageFields
      }
      dashboardImage {
        ...helicopterImageFields
      }
      extraImage {
        ...helicopterImageFields
      }
    }
    html
  }
`;
