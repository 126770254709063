import React from "react";
import Link, { withPrefix } from "gatsby-link";

const NavLink = ({ current, path, children, className }) => (
  <li>
    {current === withPrefix(path) ? (
      <span>{children}</span>
    ) : (
      <Link to={path} className={className}>
        {children}
      </Link>
    )}
  </li>
);

export default NavLink;
